import { Divider, PageHeader, Slider, Space, Spin, Switch, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import { getLottoMaster, logout, updateLottoMaster } from "../../services/services";
import Notification from "../../components/notification/notification";

const { Title, Text, Paragraph } = Typography;

// const formatter = (value: number | undefined) => {
//     return `${value}%`;
// }

const Setting = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [lottoMaster, setLottoMaster] = useState([]);

    const getAllLottoMaster = useCallback(async () => {
        const response = await getLottoMaster();
        setLottoMaster(response.data.data);
    }, []);

    useEffect(() => {
        setLoading(true);
        getAllLottoMaster().then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }, [getAllLottoMaster]);

    const onChangeAuto = async (e: boolean, v: any) => {
        setLoading(true);
        try {
            const body = {
                isAuto: e ? 'active' : 'inactive'
            };

            const response = await updateLottoMaster(v.id, body);
            if (response.status === 200) {
                Notification('success', 'บันทึกข้อมูลเรียบร้อย');
            } else {
                throw new Error('กรุณาลองใหม่อีกครั้ง');
            }

        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาด ' + error);
        }
        setLoading(false);
    }

    return (
        <MainLayout menu="setting">
            <Spin spinning={loading}>
                <PageHeader
                    title="ตั้งค่าออกผลอัตโนมัติ"
                    subTitle={<span> ออกผลอัตโนมัติโดยการจ่ายน้อยที่สุดคือ 0 บาท</span>}
                />
                <Space direction="vertical" style={{ padding: '0px 24px' }}>
                    {lottoMaster.map((v: any) => {
                        return (
                            <Space>
                                <span key={v["id"]} style={{ fontSize: 16 }}>{v["name"]}</span>
                                <Switch key={v["id"]} defaultChecked={v.isAuto === 'active' ? true : false} onChange={(e) => onChangeAuto(e, v)} />
                            </Space>
                        )
                    })}
                </Space>

                <Divider />
            </Spin>
        </MainLayout>
    )
}

export default Setting;