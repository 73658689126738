import './App.less';
import React, { FC } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import routes, { MyRoute } from './routes/routes';

const App: FC = () => {

  const renderRoutes = () => {
    const allRoutes = routes.map((route: MyRoute, index: number) => {
      if (route.nestedRoutes) {
        return <Route
          path={route.path}
          render={({ match: { url } }) => {
            return (
              <>
                <Route exact={route.exact} key={index} path={route.path} component={route.component} />
                {
                  route.nestedRoutes?.map((nestedRoute: MyRoute, index: number) => {
                    return <Route key={`nested ${index}`} path={`${url}/${nestedRoute.name}`} component={nestedRoute.component} exact />
                  })
                }
              </>
            )
          }}
        />
      }
      return <Route exact={route.exact} key={index} path={route.path} component={route.component} />
    });
    return allRoutes;
  }
  return (
    <BrowserRouter>
      <Switch>
        {renderRoutes()}
      </Switch>
    </BrowserRouter>
  );
}

export default App;