import { ClockCircleOutlined, FileDoneOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useState } from 'react';
import liveIcon from '../../assets/images/live-icon.png';
import lineIcon from '../../assets/images/line-icon.png';
import telegramIcon from '../../assets/images/telegram-icon.png';
import { useHistory } from 'react-router-dom';
import styles from './detail-sider.module.css'
import { Assets } from '../../assets';

moment.locale('th')


const { Text, Link, Title } = Typography;

type Props = {
    time: number
}

const DetailSider: FC<Props> = ({ time }) => {

    let history = useHistory();
    const [profile, setProfile] = useState({ nickname: localStorage.getItem("name") })
    const [role, setRole] = useState(localStorage.getItem("role"))

    const onLogout = () => {
        localStorage.clear();
        setTimeout(() => {
            history.push("/");
        }, 1500)
    }

    return (
        <>
            <div className="user-detail">

                <div className={styles.userInfoWrapper}>
                    <div className='space'>
                        <Text strong style={{ color: '#fff', paddingRight: '0.5rem' }}>ผู้ใช้งาน:</Text>
                        <Text style={{ color: '#fff' }}>{profile.nickname}</Text>
                    </div>
                    <div className='space'>
                        <Text strong style={{ color: '#fff', paddingRight: '0.5rem' }}>ระดับ:</Text>
                        <Text style={{ color: '#fff' }}>{role}</Text>
                    </div>
                    <Button block style={{ backgroundColor: 'white', color: 'var(--green2)', borderColor: 'var(--yellow1)' }}>เปลี่ยนรหัสผ่าน</Button>
                    <Button block style={{ backgroundColor: 'var(--yellow1)', color: 'var(--green2)', borderColor: 'var(--green1)' }} onClick={() => onLogout()}> ออกจากระบบ</Button>
                    <Divider style={{ border: '1px solid var(--green2)', margin: '0px 0px 0px 0px', padding: '0' }} />
                    <Text style={{ color: 'var(--green2)', textAlign: 'center' }}>วัน{moment(time).add(543, 'years').format("ddd D MMMM yyy")}</Text>
                    <Divider style={{ border: '1px solid var(--green2)', margin: '0px 0px 0px 0px', padding: '0' }} />
                    <section className={styles.financialStatus}>
                        <Text style={{ color: 'var(--green2)', textAlign: 'center' }}>สถานะการเงิน:</Text>
                        <Text style={{ color: 'var(--green2)', textAlign: 'center' }}></Text>
                    </section>
                    <section>
                        <Text style={{ color: 'var(--green2)', textAlign: 'center' }}>ใช้ไป:</Text>
                        <Text style={{ color: 'var(--green2)', textAlign: 'center' }}></Text>
                    </section>
                    <section>
                        <Text style={{ color: 'var(--green2)', textAlign: 'center' }}>คงเหลือ:</Text>
                        <Text style={{ color: 'var(--green2)', textAlign: 'center' }}></Text>
                    </section>
                    <Divider style={{ border: '1px solid var(--green2)', margin: '0px 0px 0px 0px', padding: '0' }} />
                    <Text style={{ color: 'var(--yellow1)' }}>UPDATE VERSION V1</Text>
                    <Text style={{ color: 'var(--yellow1)'}}>เมื่อ {moment(time).add(543, 'years').format("ddd D MMMM yyy")}</Text>
                </div>
                <img src={Assets.SideBarDragon} alt="dragon" />
            </div>

        </>
    )
}

export default DetailSider;