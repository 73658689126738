import { Button, Divider, Form, Input, Popconfirm, Select, Space, Spin, Tabs, Typography } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import Notification from "../../components/notification/notification";
import { getOverViewCalculateByPeriod, getPeriodsOnly, logout, resetPeriod, updateAnnouncePeriod } from "../../services/services";
import { utils } from "../../services/utils";
import { PeriodType } from "../../types/period.type";
import './announce.css';

const { Title, Text, Paragraph } = Typography;

const Announce: FC = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [periods, setPeriods] = useState<any>(null);
    const [periodSelected, setPeriodSelected] = useState<any>(null);
    const [result, setResult] = useState<any>(null);
    const [form] = Form.useForm();

    const getPeriods = useCallback(async () => {
        const date = new Date();

        let filter = '?filter=dateOpen||$lte||' + moment(date).format('YYYY-MM-DD 07:00:00');
        filter += '&filter=dateOpen||$gte||' + moment(date).subtract(7, 'day').format('YYYY-MM-DD 07:00:00');
        filter += '&sort=id,DESC';
        const response = await getPeriodsOnly(filter);
        setPeriods(response.data.data);
    }, []);

    useEffect(() => {
        getPeriods().then(() => setLoading(false)).catch(() => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }, [getPeriods])

    const refreshPeriod = async (periodId: any) => {
        setLoading(true);
        try {
            const response = await getOverViewCalculateByPeriod(parseInt(periodId));
            setResult(response.data);

        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาด ' + error)
        }
        setLoading(false);
    }

    const onChangePeriod = async (e: any) => {
        const period: PeriodType = periods.find((v: PeriodType) => v.id === parseInt(e));

        form.setFieldsValue({
            up6: period.up6,
            down6: period.down6
        });

        setPeriodSelected(period);
        await refreshPeriod(e);
    }

    const convertToPeriodName = (v: PeriodType) => {
        const createdDate = moment(v.dateAnnounce).add(543, 'year').toDate();
        const periodDate = moment(createdDate).format('วันที่ DD MMMM YYYY');
        const name = "หวย" + v.lottoMaster?.name + " (" + periodDate + ")";
        return name;
    }

    const renderBet = (type: string) => {
        if (!result) return <></>;
        const data = type === 'up' ? result.up.data : result.down.data;
        const rows = [...Array(Math.ceil(data.length / 5))];
        const dataDownRows = rows.map((row, idx) => data.slice(idx * 5, idx * 5 + 5));
        return dataDownRows.map((row, index) => {

            return (
                <tr key={index} className="text-center">
                    <td style={{ width: '5%' }}><span style={{ color: '#108ee9' }}>{row[0].numbers}</span></td>
                    <td style={{ width: '15%' }} className="text-right"><span style={{ color: '#c41d7f' }}>{utils.numberWithCommas(row[0].pay)}</span></td>
                    <td style={{ width: '5%' }}><span style={{ color: '#108ee9' }}>{row[1].numbers}</span></td>
                    <td style={{ width: '15%' }} className="text-right"><span style={{ color: '#c41d7f' }}>{utils.numberWithCommas(row[1].pay)}</span></td>
                    <td style={{ width: '5%' }}><span style={{ color: '#108ee9' }}>{row[2].numbers}</span></td>
                    <td style={{ width: '15%' }} className="text-right"><span style={{ color: '#c41d7f' }}>{utils.numberWithCommas(row[2].pay)}</span></td>
                    <td style={{ width: '5%' }}><span style={{ color: '#108ee9' }}>{row[3].numbers}</span></td>
                    <td style={{ width: '15%' }} className="text-right"><span style={{ color: '#c41d7f' }}>{utils.numberWithCommas(row[3].pay)}</span></td>
                    <td style={{ width: '5%' }}><span style={{ color: '#108ee9' }}>{row[4].numbers}</span></td>
                    <td style={{ width: '10%' }} className="text-right"><span style={{ color: '#c41d7f' }}>{utils.numberWithCommas(row[4].pay)}</span></td>
                </tr>
            );
        });
    }

    const save = async (values: any) => {
        setLoading(true);
        try {
            const body = {
                up6: values.up6,
                up3: values.up6.substring(3, values.up6.length),
                up2: values.up6.substring(4, values.up6.length),
                down6: values.down6,
                down2: values.down6.substring(4, values.down6.length)
            };

            const response = await updateAnnouncePeriod(periodSelected.id, body);
            if (response.status === 200) {
                Notification('success', 'บันทึกข้อมูลเรียบร้อย');
            } else {
                throw new Error('กรุณาลองใหม่อีกครั้ง');
            }

        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาด ' + error);
        }
        setLoading(false);
    }

    const resetAnnouncePeriod = async () => {
        try {
            const response = await resetPeriod(periodSelected.id);
            if (response.status === 200) {
                form.setFieldsValue({
                    up6: null,
                    down6: null
                });
                Notification('success', 'เริ่มใหม่เรียบร้อยแล้ว');
            } else {
                throw new Error('กรุณาลองใหม่อีกครั้ง');
            }
        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาด ' + error);
        }
    }

    return (
        <Spin spinning={loading}>
            <MainLayout menu="announce">
                <Typography style={{ fontSize: 16 }}>
                    <Title level={4}>ออกผลรางวัล</Title>
                    <Space>
                        <Select
                            placeholder="เลือกงวด"
                            onChange={(e) => onChangePeriod(e)}
                            style={{ width: '350px' }}
                        >
                            {periods ? periods.map((v: PeriodType) => <Select.Option key={v.id}>{convertToPeriodName(v)}</Select.Option>) : <></>}
                        </Select>
                    </Space>
                    <Divider className="divider-inside" />
                    {result ? <>
                        <Title level={5}>{periodSelected ? convertToPeriodName(periodSelected) : ''}</Title>
                        <Form layout="inline" form={form} onFinish={save}>
                            <Form.Item
                                label="เลขบน"
                                name="up6"
                                rules={[{ required: true, message: 'ระบุสำหรับออกผลเลขบน' }, {
                                    pattern: /^(?:\d*)$/,
                                    message: "กรุณาระบุเพียงตัวเลข",
                                }]}
                            >
                                <Input maxLength={6} placeholder="เลขบน 6 ตัว" />
                            </Form.Item>
                            <Form.Item
                                label="เลขล่าง"
                                name="down6"
                                rules={[{ required: true, message: 'ระบุสำหรับออกผลเลขล่าง' }, {
                                    pattern: /^(?:\d*)$/,
                                    message: "กรุณาระบุเพียงตัวเลข",
                                }]}
                            >
                                <Input maxLength={6} placeholder="เลขล่าง 6 ตัว" />
                            </Form.Item>
                            <Form.Item >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    ออกผล
                                </Button>
                            </Form.Item>
                            {periodSelected ?
                                <Form.Item >
                                    <Popconfirm placement="topLeft" title={'ต้องการเริ่มออกผลใหม่ใช่หรือไม่'} onConfirm={resetAnnouncePeriod} okText="ใช่" cancelText="ไม่ใช่">
                                        <Button
                                            type="default"
                                            htmlType="button"
                                        >
                                            เริ่มใหม่
                                        </Button>
                                    </Popconfirm>
                                </Form.Item> : <></>}
                            {periodSelected ?
                                <Form.Item >
                                    <Button
                                        type="default"
                                        htmlType="button"
                                        onClick={() => refreshPeriod(periodSelected.id)}
                                    >
                                        Refresh
                                    </Button>
                                </Form.Item> : <></>}
                        </Form>
                        <Divider className="divider-inside" />
                    </> : <></>}
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="เลขบน" key={1}>
                            {result ?
                                <div>
                                    <table className="announce">
                                        <thead>
                                            <tr className="text-center">
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderBet('up')}
                                        </tbody>
                                    </table>
                                </div> : <></>}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'เลขล่าง'} key={2}>
                            {result ?
                                <div>
                                    <table className="announce">
                                        <thead>
                                            <tr className="text-center">
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                                <th style={{ backgroundColor: '#2db7f5', color: 'white' }}>เลข</th>
                                                <th style={{ backgroundColor: '#d4380d', color: 'white' }} className="text-right">จ่าย</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderBet('down')}
                                        </tbody>
                                    </table>
                                </div> : <></>}
                        </Tabs.TabPane>
                    </Tabs>
                </Typography>
            </MainLayout>
        </Spin>
    );
}

export default Announce;