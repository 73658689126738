import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Descriptions, Empty, Modal, PageHeader, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../../components/main-layout/main-layout";
import { utils } from "../../../services/utils";
import { BetType } from "../../../types/bet.type";
import { BillType } from "../../../types/bill.type";
import { PeriodType } from "../../../types/period.type";
import  '../../bet/bet.css';

const { Title } = Typography;

const renderBillColumns = (period: PeriodType, onShowBetsInBill: Function) => {
    const createdDate = moment(period.createdAt).add(543, 'year').toDate();
    const periodDate = moment(createdDate).format('วันที่ DD MMMM YYYY');
    const announceDate = moment(moment(period.dateAnnounce).toDate()).format('เวลา HH:mm น.');

    const columns: ColumnsType<any> = [
        {
            title: <b>#</b>,
            dataIndex: '',
            key: '',
            render: (value: any, record: any, index: number) => {
                return index + 1;
            }
        },
        {
            title: <b>เวลาแทง</b>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: (value: any, record: any, index: number) => {
                return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
            }
        },
        {
            title: <b>ตลาด</b>,
            dataIndex: 'lottoName',
            key: 'lottoName',
            align: 'center',
            render: (value: any, record: any, index: number) => {
                return period.lottoMaster.name;
            }
        },
        {
            title: <b>งวดวันที่</b>,
            dataIndex: 'dateAnnounce',
            key: 'dateAnnounce',
            align: 'center',
            render: (value: any, record: any, index: number) => {
                return periodDate + " (" + announceDate + ")";
            }
        },
        {
            title: <b>รายการ</b>,
            dataIndex: 'bets',
            key: 'bets',
            align: 'right',
            render: (value: any, record: any, index: number) => {
                return record.bets.length;
            }
        },
        {
            title: <b>ยอดแทง</b>,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            className: 'price',
            render: (value: any, record: any, index: number) => {
                return utils.numberWithCommas(value);
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            className: 'commission',
            render: (value: any, record: any, index: number) => {
                let commission = 0;
                record.bets.forEach((v: BetType) => {
                    commission += v.commission ||= 0;
                    return v;
                });
                return utils.numberWithCommas(commission + "");
            }
        },
        {
            title: <b>ถูกรางวัล</b>,
            dataIndex: 'net',
            key: 'net',
            align: 'right',
            className: 'net',
            render: (value: any, record: any, index: number) => {
                // const showResult = period.showResult;
                // if (showResult === 'false') return '0.00';
                return utils.numberWithCommas(record.pay);
            }
        },
        {
            title: <b>ได้เสีย</b>,
            dataIndex: 'betTotal',
            key: 'betTotal',
            align: 'right',
            // className: 'net',
            render: (value: any, record: any, index: number) => {
                let commission = 0;
                record.bets.forEach((v: BetType) => {
                    // v.commission = (v.betDataMaster.commissionRateMaster * 1.00) * (v.price / 100.00);
                    commission += v.commission ||= 0;
                    return v;
                });

                // const showResult = period.showResult;
                // if (showResult === 'false') return '';
                // const sum = ((showResult === 'false' ? 0 : record.pay) + commission) - record.price;
                const sum = (record.pay + commission) - record.price;
                return <span style={{ color: sum > 0 ? '#2db7f5' : '#cf1322' }}>{utils.numberWithCommas(sum + "")}</span>;
            }
        },
        {
            title: <b>หมายเหตุ</b>,
            dataIndex: 'detail',
            key: 'detail',
            align: 'right'
        },
        {
            title: <b>รายการแทง</b>,
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return <Button type="link" onClick={() => onShowBetsInBill(record)}>ดูรายการแทง</Button>
            }
        }
    ];

    return columns;
}

const ResultBill: FC = (props: any) => {

    const betColumns: ColumnsType<any> = [
        {
            title: <b>ประเภท @ หมายเลข</b>,
            dataIndex: 'numbersBetDataMaster',
            key: 'numbersBetDataMaster',
            align: 'center',
            render: (text: string, record: BetType, index: number) => {
                return record.betDataMaster.name + " @ " + record.numbers;
            }
        },
        {
            title: <b>ยอดซื้อ</b>,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            className: 'price',
            render: (text: string, record: BetType, index: number) => {
                return utils.numberWithCommas(text);
            }
        },
        {
            title: <b>ยอดจ่าย</b>,
            dataIndex: 'net',
            key: 'net',
            align: 'right',
            className: 'net',
            render: (text: string, record: BetType, index: number) => {
                // const showResult = period.period.showResult;
                // if (showResult === 'false') return '0.00';
                
                return record.earn > 0 ? utils.numberWithCommas(record.price + "") : utils.numberWithCommas(record.commission + "");
            }
        },
        {
            title: <b>อัตราจ่าย</b>,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (text: string, record: BetType, index: number) => {
                return record.betDataMaster.earnPrice;
            }
        },
        {
            title: <b>สถานะ</b>,
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text: string, record: BetType, index: number) => {
                // const showResult = period.period.showResult;
                // if (showResult === 'false') return 'ไม่ถูกรางวัล';

                return record.status === 'win' ? <><span style={{ color: '#39c210' }}>ถูกรางวัล</span> = <span className="net">{utils.numberWithCommas(record.earn + "")}</span></> : 'ไม่ถูกรางวัล';
            }
        },
    ];

    let history = useHistory();
    const [loading, isLoading] = useState(false);
    const [period, setPeriod] = useState<any>(props.location.state?.period);
    const [billTemp, setBillTemp] = useState(period.period.bills);
    const [isShowBill, setIsShowBill] = useState(false);
    const [currentBetsInBill, setCurrentBetsInBill] = useState<any>([]);
    const [currentBill, setCurrentBill] = useState<BillType>();
    const [isSelected, setIsSelected] = useState(true);

    const createdDate = moment(period.period.createdAt).add(543, 'year').toDate();
    const periodDate = moment(createdDate).format('วันที่ DD MMMM YYYY');
    const announceDate = moment(moment(period.period.dateAnnounce).toDate()).format('เวลา HH:mm น.');

    const renderBets = () => {
        return [...currentBetsInBill];
    }

    const onShowBetsInBill = (bill: BillType) => {
        setIsShowBill(true);
        setCurrentBill(bill);
        setCurrentBetsInBill(bill.bets);
    }

    const rowBillSetup = (record: BillType, index: number) => {
        // const showResult = period.period.showResult;
        // if (showResult === 'false') return '';

        if (record.pay > 0) {
            return 'pay-bill';
        }
        return '';
    }

    const rowBetSetup = (record: BetType, index: number) => {

        // const showResult = period.period.showResult;
        // if (showResult === 'false') return '';

        if (record.status === 'win') {
            return 'pay-bill';
        }
        return '';
    }

    const onChangeBill = (status: string) => {
        const data = [...period.period.bills];

        if (status === 'all') {
            setBillTemp(data);
        } else {
            const onlyPay = data.filter((v: any) => v.pay > 0);
            setBillTemp(onlyPay);
        }

        setIsSelected(!isSelected);
    }

    return (
        <MainLayout menu="result/bill">
            <Spin spinning={loading}>
                <PageHeader
                    style={{ padding: 0, marginBottom: 10 }}
                    title={<Button style={{ borderRadius: 20 }} type={'primary'} onClick={() => history.goBack()}><ArrowLeftOutlined /> กลับหน้าสรุปผล</Button>}
                    extra={[
                        <Button key="1" type={isSelected ? 'primary' : 'default'} onClick={() => onChangeBill('all')}>บิลทั้งหมด</Button>,
                        // <Button key="2" disabled={period.period.showResult === "true" ? false : true} type={!isSelected ? 'primary' : 'default'} onClick={() => onChangeBill('pay')}>บิลถูกรางวัล</Button>,
                        <Button key="2" type={!isSelected ? 'primary' : 'default'} onClick={() => onChangeBill('pay')}>บิลถูกรางวัล</Button>,
                    ]}
                />
                <Title level={4}>สรุปผลรายบิล {`${period.period.lottoMaster.name} ${periodDate} (${announceDate})`}</Title>

                <Table rowClassName={rowBillSetup} size="small" columns={renderBillColumns(period.period, onShowBetsInBill)} dataSource={billTemp} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={false} />

                <Modal title={<b>{period ? period.period.lottoMaster.name + " " + moment(period.period.dateAnnounce).format("วันที่ DD-MM-YYYY") : 'ข้อมูลโพย'}</b>}
                    open={isShowBill}
                    maskClosable={false}
                    onOk={() => setIsShowBill(false)}
                    onCancel={() => setIsShowBill(false)}
                    width={1000}
                    okText="ปิด"
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Descriptions>
                        <Descriptions.Item label="หมายเหตุ">{currentBill?.detail}</Descriptions.Item>
                    </Descriptions>
                    <Table
                        rowClassName={rowBetSetup}
                        className="bet-report"
                        columns={betColumns}
                        dataSource={renderBets()}
                        size="small"
                        locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}
                        pagination={false}
                    // summary={(dataSummary: readonly BetType[]) => renderSummary(dataSummary)}
                    />
                </Modal>
            </Spin>
        </MainLayout>
    )
}

export default ResultBill;