import { MdPersonAddAlt1 } from 'react-icons/md';
import { LockOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Row, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/notification/notification';
import { login } from '../../services/services';
import './login.css';
import { Assets } from '../../assets';


const Login: FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) history.push("/");

    }, [history]);

    const onLogin = async (values: any) => {
        setLoading(true);
        const { username, password } = values;

        try {
            const response = await login(username, password);
            // console.log(response);
            if (response.status === 200) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('name', response.data.nickname);
                localStorage.setItem('memberId', response.data.id);
                localStorage.setItem('role', response.data.rank.name);
                setLoading(true);
                history.push("/");
            } else {
                setLoading(false);
                Notification('error', 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
            }
        } catch (error) {
            setLoading(false);
            Notification('error', 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
        }
    };

    return (
        <Spin spinning={loading}>
            <Row justify="center" className="bg">
                <Form
                    form={form}
                    style={{width: '100dvw', maxWidth: '420px', userSelect: 'none' }}
                    className="card"
                    name="signin"
                    onFinish={onLogin}
                >
                    <Card>
                        {/* <div className="header-login">
                            <h4>เข้าสู่ระบบ @Golden UP</h4>
                            <div className="icon-line">
                                <a href="#" target="_blank">
                                    <HomeOutlined style={{ fontSize: '35px', color: '#6F7DBE' }} />
                                </a>
                            </div>
                        </div> */}
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาใส่ชื่อผู้ใช้',
                                },
                            ]}
                        >
                            <Input className="real-input" size="large" maxLength={32} prefix={<MdPersonAddAlt1 style={{ color: 'var(--green2)', fontSize: '1.3rem' }} />} placeholder="ชื่อผู้ใช้" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาใส่รหัสผ่าน',
                                },
                            ]}
                        >
                            <Input className="real-input" size="large" maxLength={32} prefix={<LockOutlined style={{ color: 'var(--green2' }} />} type="password" placeholder="รหัสผ่าน" />
                        </Form.Item>

                        <div className="footer">
                            <Button className="btn" htmlType={'submit'}>
                                <img src={Assets.LoginBtn} width={126} height={126} draggable={false} />
                            </Button>
                        </div>
                    </Card>
                </Form>
            </Row>
        </Spin>
    )
}

export default Login;